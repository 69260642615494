<template>
  <layout>
    <v-container fluid>
      <v-data-iterator
        :items="tv"
        :items-per-page.sync="itemsPerPage"
        :page="page"
        :search="search"
        :sort-by="sortBy.toLowerCase()"
        :sort-desc="sortDesc"
        hide-default-footer
      >
        <template v-slot:header>
          <h2>Filters</h2>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                v-model="search"
                clearable
                flat
                solo-inverted
                hide-details
                prepend-inner-icon="search"
                label="Search by name"
              ></v-text-field>
            </v-col>
            <!--
            <v-col cols="12" sm="6" md="4">
              <v-select
                v-model="filters.genres"
                flat
                dense
                solo-inverted
                multiple
                chips
                deletable-chips
                item-text="name"
                item-value="id"
                :items="genres"
                prepend-inner-icon="category"
                label="Genres"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index <= 1">
                    <span>{{ item.name }}</span>
                  </v-chip>
                  <span
                    v-if="index === 2"
                    class="grey--text caption"
                  >(+{{ filters.genres.length - 1 }} others)</span>
                </template>
              </v-select>
            </v-col>
            -->
            <v-col cols="9" sm="10" md="4" xl="5">
              <v-select
                v-model="sortBy"
                flat
                solo-inverted
                hide-details
                :items="keys"
                prepend-inner-icon="filter_list"
                label="Sort by"
                hint="Sort by"
                persistent-hint
              ></v-select>
            </v-col>
            <v-col cols="3" sm="2" md="2" xl="1">
              <v-btn-toggle
                v-model="sortDesc"
                mandatory
              >
                <v-btn
                  depressed
                  color="blue"
                  :value="false"
                >
                  <v-icon>arrow_upward</v-icon>
                </v-btn>
                <v-btn
                  depressed
                  color="blue"
                  :value="true"
                >
                  <v-icon>arrow_downward</v-icon>
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </template>

        <template v-slot:default="props">
          <v-item-group>
            <v-row>
              <v-col
                v-for="item in props.items"
                :key="item.id"
                cols="4"
                sm="2"
                lg="1"
                class="pa-1 d-flex child-flex"
              >
                <v-item v-slot:default="{ active, toggle }">
                  <Tv :active="active" :action="toggle" :tv="item"></Tv>
                </v-item>
              </v-col>
            </v-row>
          </v-item-group>
        </template>

        <template v-slot:footer>
          <v-footer fixed app inset>
            <v-row align="center" justify="center">
              <v-col cols="4" class="py-0" align="center" justify="center">
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      dark
                      text
                      color="primary"
                      class="ml-2"
                      v-on="on"
                    >
                      {{ itemsPerPage }} / page
                      <v-icon>keyboard_arrow_down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(number, index) in itemsPerPageArray"
                      :key="index"
                      @click="updateItemsPerPage(number)"
                    >
                      <v-list-item-title>{{ number }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
              <v-col cols="4" class="py-0" align="center" justify="center">
                Page {{ page }} of {{ numberOfPages }}
              </v-col>
              <v-col cols="4" class="py-0" align="center" justify="center">
                <v-row>
                  <v-col cols="6" align="right">
                    <v-btn
                      small
                      color="blue darken-3"
                      @click="formerPage"
                    >
                      <v-icon>keyboard_arrow_left</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="6" align="left">
                    <v-btn
                      small
                      color="blue darken-3"
                      @click="nextPage"
                    >
                      <v-icon>keyboard_arrow_right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-footer>
        </template>
      </v-data-iterator>
    </v-container>
    <v-overlay :value="tv.length === 0">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </layout>
</template>

<script>
import Layout from '../Layout';
import firebase from 'firebase/app';
import 'firebase/firestore';
import Tv from '../components/Tv';

export default {
  data () {
    return {
      tv: [],
      itemsPerPageArray: [20, 40, 100],
      search: '',
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: 20,
      sortBy: 'name',
      keys: [
        { text: 'Name', value: 'name' },
        { text: 'Vote average', value: 'vote_average' },
        { text: 'Popularity', value: 'popularity' },
        { text: 'IMdb Rating', value: 'imdb_rating' },
        { text: 'Release date', value: 'release_date' },
        { text: 'Date Added', value: 'date_added' }
      ],
      filters: {
        genres: [],
        range: [1950, 2020],
        text: null
      }
    };
  },
  components: {
    Tv,
    Layout
  },
  computed: {
    numberOfPages () {
      return Math.ceil(this.tv.length / this.itemsPerPage)
    },
    genres () {
      return this.$store.state.genres;
    }
  },
  methods: {
    nextPage () {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage () {
      if (this.page - 1 >= 1) this.page -= 1
    },
    updateItemsPerPage (number) {
      this.itemsPerPage = number
    }
  },
  created: function () {
    let firestore = firebase.firestore();
    let tvRef = firestore.collection('tv');

    tvRef.get()
      .then(snapshot => {
        if (snapshot.empty) {
          console.log('No matching documents.');
          return;
        }

        this.tv = [];

        snapshot.forEach(doc => {
          this.tv.push(doc.data());
        });
      })
      .catch(err => {
        console.log('Error getting documents', err);
      });
  }
};
</script>
